/* Light */
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-LightIt.woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Regular */
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Italic.woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Medium */
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-MediumIt.woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Demi */
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Demi.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-DemiIt.woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirNextWorld";
  src: url("/fonts/AvenirNextWorld-BoldIt.woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
